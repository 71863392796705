import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import TextUnderline from '../TextUnderline';
import Button from '../Button';
import Illustration from '../Illustration';
import * as styles from './featuredSurvey.module.scss';

const FeaturedSurveys = ({ surveys }) => {
  return (
    <section className={styles.featuredCompanies}>
      {surveys &&
        surveys.map((survey, index) => {
          return (
            <div
              key={index}
              className={[
                isEven(index) ? styles.light : styles.dark,
                styles.featuredCompany,
                'text-stroke-featured-parent',
              ].join(' ')}>
              <div className={styles.inner}>
                <div className={styles.imageryAndContent}>
                  <div className={styles.imagery}>
                    <div className={styles.imageryInner}>
                      {survey.featureHeroImage && (
                        <div className={styles.illustration}>
                          <Illustration
                            src={survey.featureHeroImage.file.url}
                            patternLocation={isEven(index) ? 'right' : 'left'}
                            patternStyle={'light'}
                            hoverEffect={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.content}>
                    <h5>{survey.featureDateCreate}</h5>
                    {survey.featureTitle && (
                      <h2 className={styles.headline}>
                        <TextUnderline
                          headline={survey.featureTitle}
                          headlineUnderline=""
                          underlineTheme={isEven(index) ? 'canvasTan' : 'canvasDarkJean'}
                        />
                      </h2>
                    )}
                    <div className={styles.ctaButton}>
                      <Button
                        theme={'jean'}
                        text="Learn More"
                        url={survey.linkToPage}
                        type={survey.linkToPage.indexOf('http') !== -1 ? 'external' : ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
};

const isEven = number => {
  return number % 2 === 0;
};

FeaturedSurveys.propTypes = {
  index: PropTypes.number,
  surveys: PropTypes.array,
};

export default FeaturedSurveys;

export const query = graphql`
  fragment FeaturedSurveyQuery on ContentfulReportPage {
    featuredSurveys {
      featureTitle
      featureDateCreate
      featureHeroImage {
        file {
          url
        }
      }
      linkToPage
    }
  }
`;
