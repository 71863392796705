// extracted by mini-css-extract-plugin
export var featuredCompanies = "featuredSurvey-module--featuredCompanies--Q2BXP";
export var featuredCompany = "featuredSurvey-module--featuredCompany--2Unf1";
export var dark = "featuredSurvey-module--dark--2NGHi";
export var light = "featuredSurvey-module--light--1LUF4";
export var inner = "featuredSurvey-module--inner--k3wnn";
export var imageryAndContent = "featuredSurvey-module--imageryAndContent--3YarO";
export var imagery = "featuredSurvey-module--imagery--1tYms";
export var imageryInner = "featuredSurvey-module--imageryInner--15SqD";
export var companyName = "featuredSurvey-module--companyName--2Z-Uy";
export var illustration = "featuredSurvey-module--illustration--3rN2F";
export var content = "featuredSurvey-module--content--BpP3M";
export var headline = "featuredSurvey-module--headline--Iq9gv";
export var ctaButton = "featuredSurvey-module--ctaButton--rjMmG";
export var ctaLink = "featuredSurvey-module--ctaLink--3L1dh";
export var thesis = "featuredSurvey-module--thesis--aAzof";
export var thesisLabel = "featuredSurvey-module--thesisLabel--QejIC";
export var thesisNames = "featuredSurvey-module--thesisNames--1yThq";
export var thesisName = "featuredSurvey-module--thesisName--2CUc1";