import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import Newsletter from '../../components/Newsletter';
import Waves from '../../components/HomePage/Waves';
import ArticleSection from '../../components/ArticleSection';
import Pagination from '../../components/Pagination';
import FeaturedSurveys from '../../components/FeaturedSurveys';
import Header from '../../components/Archive/Header';
import ArticleCard from '../../components/ArticleCard';
import GeneralLayout from '../../layouts/index';
import * as styles from './deskless.module.scss';

const Deskless = ({ transition, data, pageContext, ...rest }) => {
  const { group, index, pageCount, pathPrefix } = pageContext;
  const { contentfulReportPage, contentfulNewsletter, site } = data;
  const {
    reportSubtitle,
    reportTitle,
    description,
    featured,
    seo,
    featuredSurveys,
    bottomSection,
  } = contentfulReportPage;
  let press;
  bottomSection.__typename === 'ContentfulPress' ? (press = true) : (press = false);

  return (
    <GeneralLayout>
        <Theme transition={transition} theme="blue">
          <Seo siteData={site} seoData={seo} defaultTitle="Emergence" pathName="/" />

          {featured.__typename !== 'ContentfulThought' ? (
            <Header
              overline={reportSubtitle}
              title={reportTitle}
              description={description}
              featuredAnnouncement={featured}
            />
          ) : (
            <Header
              overline={reportSubtitle}
              title={reportTitle}
              description={description}
              featuredArticle={featured}
            />
          )}

          {featuredSurveys && <FeaturedSurveys surveys={featuredSurveys} />}

          <Waves>
            <section className={styles.introduction}>
              <div className={styles.inner}>
                {bottomSection && (
                  <ArticleCard
                    article={bottomSection}
                    featuredArticle={true}
                    press={press}
                    deskLess={true}
                  />
                )}
              </div>
            </section>
          </Waves>

          {group && <ArticleSection articles={group} largeArticle={false} />}
          {pageCount > 1 && <Pagination index={index} pageCount={pageCount} pathPrefix={pathPrefix} />}

          <Newsletter content={contentfulNewsletter} />
        </Theme>
    </GeneralLayout>
  );
};

Deskless.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Deskless;

export const query = graphql`
  query desklessQuery {
    contentfulReportPage: contentfulReportPage {
      reportSubtitle
      reportTitle
      description {
        description
      }
      featured {
        __typename
        ... on Node {
          ... on ContentfulThought {
            title
            subtitle
            slug
            publishedDate(formatString: "MM.DD.YYYY")
            author {
              name
              title
              slug
              portrait {
                fluid {
                  src
                }
              }
            }
            secondaryAuthor {
              name
              title
              slug
              portrait {
                fluid {
                  src
                }
              }
            }
            tags {
              ... on ContentfulTag {
                name
                slug
              }
              ... on ContentfulThesis {
                name
                slug
              }
            }
          }
          ... on ContentfulAnnouncement {
            title
            description {
              description
            }
            link
          }
        }
      }
      bottomSection {
        __typename
        ... on Node {
          ... on ContentfulThought {
            title
            subtitle
            slug
            publishedDate(formatString: "MM.DD.YYYY")
            author {
              name
              title
              slug
              portrait {
                fluid {
                  src
                }
              }
            }
            secondaryAuthor {
              name
              title
              slug
              portrait {
                fluid {
                  src
                }
              }
            }
            tags {
              ... on ContentfulTag {
                name
                slug
              }
              ... on ContentfulThesis {
                name
                slug
              }
            }
          }
          ... on ContentfulPress {
            title
            url
            publication
            publishedDate(formatString: "MM.DD.YYYY")
            hideOnPressPage
            relatedContent {
              ... on ContentfulPerson {
                __typename
                name
                slug
              }
              ... on ContentfulCompany {
                __typename
                name
                slug
              }
            }
          }
        }
      }
      ...FeaturedSurveyQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    contentfulThoughtsPage: contentfulThoughtsPage {
      ...FeaturedThoughtsQuery
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
