import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import anime from 'animejs';

import * as styles from '../FeaturedTheses/featuredTheses.module.scss';

class Waves extends Component {
  componentDidMount() {
    this.thesesBlob(this.refs.thesesBlob);
  }

  componentDidUpdate() {
    this.thesesBlob(this.refs.thesesBlob);
  }

  thesesBlob = el => {
    anime({
      targets: el,
      d: [
        {
          value:
            'M0,80.5022105 C41.1237435,91.8965033 278.583573,85.834859 712.37949,62.3172776 C1146.17541,38.7996963 1388.71558,43.4699787 1440,76.328125 L1440,161 L0,160 L0,80.5022105 Z',
        },
        {
          value:
            'M0,0 C161.825521,31.8802083 406.459635,47.8203125 733.902344,47.8203125 C1061.34505,47.8203125 1296.71094,79.546875 1440,143 L1440,161 L0,160 L0,0 Z',
        },
      ],
      easing: 'easeOutQuad',
      direction: 'alternate',
      duration: 10000,
      loop: true,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <section className={styles.featuredTheses}>
        <div className={styles.background}>
          <svg
            className={styles.backgroundAnimation}
            xmlns="http://www.w3.org/2000/svg"
            width="1440"
            height="160"
            viewBox="0 0 1440 160"
            preserveAspectRatio="none">
            <path
              fill="#15403D"
              ref="thesesBlob"
              d="M0,156 C41.1237435,117.062433 278.583573,85.834859 712.37949,62.3172776 C1146.17541,38.7996963 1388.71558,18.0287441 1440,0.0044210957 L1440,161 L0,160 L0,156 Z"
            />
          </svg>
        </div>

        {children}
      </section>
    );
  }
}

export default Waves;

Waves.propTypes = {
  children: PropTypes.node,
};
